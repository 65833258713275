<script>
import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import appConfig from "../../../app.config";
import { searchUsers } from "@/helpers/components/users";

export default {
  page: {
    title: "Пользователи",
    meta: [{ name: "description", content: appConfig.description }],
  },
  data() {
    return {
      title: "Пользователи",
      items: [
        {
          text: "Пользователи",
          href: "/users",
        },
        {
          text: "Поиск",
          active: true,
        },
      ],
      search: '',
      searching: false,
      users: [],
      current_page: 1,
      last_page: 1,
      pages: [],
      loading: true
    };
  },
  components: {
    Layout,
    PageHeader,
  },
  methods: {
    searchUsers() {
        if (this.searching) {
          return;
        }

        this.searching = true;
        setTimeout(async () => {
          await this.loadUsers();
          this.searching = false;
        }, 500);
    },
    async loadUsers(page = 1) {
      this.loading = true;

      const result = await searchUsers({
        login: this.search,
        paginate: 8,
        page,
      });

      this.users = result.data;
      this.current_page = result.current_page;
      this.last_page = result.last_page;
      this.pages = [];
      for (let index = 1; index <= result.last_page; index++) {
        this.pages.push(index);
      }

      this.loading = false;
    }
  },
  async mounted() {
    this.search = this.$route.query.search;
    console.log(this.$route.query.search);
    await this.loadUsers();
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

      <div class="card col-lg-5">
        <div class="card-body">
          <div class="row g-2">
            <div class="search-box">
              <input
                  type="text"
                  class="form-control"
                  placeholder="Поиск пользователей..."
                  v-model="search"
                  @input="searchUsers"
              />
              <i class="ri-search-line search-icon"></i>
            </div>
          </div>
          <!--end row-->
        </div>
      </div>


    <div class="position-relative mb-4"
         :class="{'opacity-25': loading }"
    >
      <div v-if="loading" class="card-preloader bg-transparent d-flex flex-column align-items-center justify-content-center">
        <div class="spinner-border text-primary" role="status">
          <span class="sr-only"></span>
        </div>
        <p class="mt-2">Загрузка...</p>
      </div>

      <div class="row">
        <div class="col-lg-12">
          <div>
            <div class="team-list grid-view-filter row">
              <div v-for="(su) in users"
                   :key="su.id"
                   class="col"
              >
                <div class="card team-box">
                  <div class="team-cover">
                    <img
                        :src="su.banner + '?mini=true'"
                        alt=""
                        class="img-fluid"
                    />
                  </div>
                  <div class="card-body p-4">
                    <div class="row align-items-center team-row">
                      <div class="col team-settings">
                        <div class="row">
                          <div class="text-end dropdown">
                            <a
                                href="javascript:void(0);"
                                id="dropDownMenu"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                            >
                              <i class="ri-more-fill fs-17"></i>
                            </a>
                            <ul
                                class="dropdown-menu dropdown-menu-end"
                                aria-labelledby="dropDownMenu"
                            >
                              <li>
                                <router-link
                                    class="dropdown-item"
                                    :to="`/users/${su.login}`"
                                >
                                  <i class="ri-eye-line me-2 align-middle"></i>
                                  Перейти
                                </router-link>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 col">
                        <div class="team-profile-img">
                          <div>
                            <img
                                :src="su.avatar"
                                alt=""
                                class="rounded-circle
                                      avatar-lg
                                      img-thumbnail
                                      user-profile-image
                              "
                            />
                          </div>
                          <div class="team-content">
                            <router-link
                                :to="`/users/${su.login}`"
                            >
                              <h5 class="fs-16 mb-1">{{ su.login }}</h5>
                            </router-link>
                            <p class="text-muted mb-0">{{ su.role_name }}</p>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-2 col">
                        <div class="text-end">
                          <router-link
                              :to="`/users/${su.login}`"
                              class="btn btn-light view-btn"
                          >
                            Открыть профиль
                          </router-link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!--end card-->
              </div>
              <!--end col-->
            </div>
            <!--end row-->
          </div>
        </div>
        <!-- end col -->
      </div>
      <!--end row-->

      <div v-if="pages?.length > 1" class="d-flex justify-content-start p-3">
        <div class="pagination-wrap hstack gap-2">
          <button
              class="page-item pagination-prev"
              :disabled="current_page === 1"
              @click="loadUsers(current_page - 1)"
          >
            <i class="ri ri-arrow-left-s-line"></i>
          </button>
          <ul class="pagination listjs-pagination mb-0">
            <li

                v-for="(pageNumber, index) in pages.slice(
                  Math.max(Math.min(current_page - 3, last_page - 5), 0),
                  Math.max(current_page + 2, 5),
              )"
                :class="{
                active: pageNumber === current_page,
              }"
                :key="index"
                @click="loadUsers(pageNumber)"
            >
              <a class="page" role="button">{{ pageNumber }}</a>
            </li>
          </ul>
          <button
              class="page-item pagination-next"
              @click="loadUsers(current_page + 1)"
              :disabled="current_page >= last_page"
          >
            <i class="ri ri-arrow-right-s-line"></i>
          </button>
        </div>
      </div>
    </div>
  </Layout>
</template>
